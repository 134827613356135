import India from '../images/India.png'
import US from '../images/US.png'
import CA from '../images/CA.png'
import UK from '../images/UK.png'
import AU from '../images/AU.png'
import DE from '../images/DE.png'
import MM from '../images/MM.png'
import Book from '../images/Book.png'
import './Globe.css'

const Globe = () => {
    return (
<div className='aboutUS-section'  >

<h2 className="sectionTitle">Making Difference across the Globe</h2>
<br/>
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
<img
          className="aboutUsStyle"
            alt="Facebook"
            src={India}
            style={{height:'5vw',width:'10vw'}}

          />
       <img
          className="aboutUsStyle"
            alt="Facebook"
            src={US}

            style={{height:'5vw',width:'10vw'}}

          /> 
               <img
          className="aboutUsStyle"
            alt="Facebook"
            src={CA}
            style={{height:'5vw',width:'10vw'}}



          /> 
               <img
          className="aboutUsStyle"
            alt="Facebook"
            src={UK}
            style={{height:'5vw',width:'10vw'}}


          /> 
                 <img
          className="aboutUsStyle"
            alt="Facebook"
            src={AU}
            style={{height:'5vw',width:'10vw'}}


          /> 
                  <img
          className="aboutUsStyle"
            alt="Facebook"
            src={DE}
            style={{height:'5vw',width:'10vw'}}


          /> 
                    <img
          className="aboutUsStyle"
            alt="Facebook"
            src={MM}
            style={{height:'5vw',width:'10vw'}}


          /> 
          
</div>
<br/>


<p
className='tableTextGlobe'>

"Upon purchasing this copy, you will be entitled to receive complimentary updates whenever <br/>new tips and strategies are added. This offer is ongoing, ensuring you have the latest insights<br/> for your professional journey. Your support strengthens our community, and we are committed <br/>to standing by you every step of the way.<br/><br/>
We are confident that you’ll find great value in your purchase. Should you wish to provide<br/>feedback or join our community, simply follow the link within the e-book.<br/><br/>
<span style={{color:'#FFA31A'}}>Wishing you success in all your endeavors."</span><br/><br/>

</p>
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-around',width:'50vw'}}>
<img
          className="GVIcon"
            alt="Facebook"
            src={Book}

          />
          <div
className='tableTextGlobeColor'>
          

            <p>Use GENZVISION coupon code at the checkout </p>
            <a href='/form'> <button  className="buttonStyle">Get your copy</button></a>

            </div>
</div>
</div>
    )}

export default Globe;
