import observingPeople from '../images/observingPeople.png'
import teamBonding from '../images/teamBonding.png'
import bondingOutsideTeam from '../images/bondingOutsideTeam.png'
import visibilityCredit from '../images/visibilityCredit.png'
import socialMediaNetworking from '../images/socialMediaNetworking.png'
import dealingBullying from '../images/dealingBullying.png'
import navigatingPolitics from '../images/navigatingPolitics.png'
import bondingHR from '../images/bondingHR.png'
import teamHandling from '../images/teamHandling.png'
import partyTips from '../images/partyTips.png'
import datingTips from '../images/datingTips.png'
import hikePromotions from '../images/hikePromotions.png'
import usingNewTechnologies from '../images/usingNewTechnologies.png'
import bonusTips from '../images/bonusTips.png'

import  './TableOfContent.css'

const TableOfContentApp = () => {
    return (
<div  className='aboutUS-section'>
<h2 className="sectionTitle">Table of Content</h2>
<div

className='imageLayout'
>
<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={observingPeople}


          />
          <p className='tableText' > Observing People</p>
</div>
<div style={{minWidth:'20vw'}} >


           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={teamBonding}


          />
          < p className='tableText' >Team Bonding</p>
</div>
<div style={{minWidth:'20vw'}} >


           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={bondingOutsideTeam}


          />
          <p className='tableText' >Bonding Outside Team</p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={visibilityCredit}


          />
          <p className='tableText' >Visibility & Credit</p>
</div>


</div>



<div
className='imageLayout'

>



<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={socialMediaNetworking}


          />
          <p className='tableText' >Social Media &  <br/>Networking</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={dealingBullying}


          />
          <p className='tableText' >Dealing Bullying </p>
</div>


<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={navigatingPolitics}


          />
          <p className='tableText' >Navigating Politics  </p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={bondingHR}


          />
          <p className='tableText' >Bonding with HR  </p>
</div>
  </div>
   

  <div
className='imageLayout'

>




<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={teamHandling}


          />
          <p className='tableText' >Team Handling</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={partyTips}


          />
          <p className='tableText' >Party Tips </p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={datingTips}


          />
          <p className='tableText' >Dating Tips</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={hikePromotions}


          />
          <p className='tableText' >Hike  Promotions</p>
</div>



  </div>


  <div
className='imageLayout'

>
<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={usingNewTechnologies}


          />
          <p className='tableText' >Using New <br/> Technologies</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="aboutUsStyle1"

            alt="Facebook"
            src={bonusTips}


          />
          <p className='tableText' >Bonus Tips</p>
</div>
<div style={{minWidth:'20vw'}} >

<a href='/form'> <button  className="buttonStyle">Get your copy</button></a>

</div>
<div style={{minWidth:'20vw'}} >
</div>

</div>




</div>
    )
}



export default TableOfContentApp;