// import React, { useState } from 'react';
// import axios from 'axios';
// import './Navbar.css'; // Import your CSS file
// import imageLogo from './imageLogo.png'
// import CustomNavbar from './Navbar';
// const App = () => {
//   const [attachment, setAttachment] = useState(''); // Attachment content
//   const headers = {
//     "Access-Control-Allow-Origin": "*",
//   }
//   const sendEmail = async () => {
//     try {
//       const response = await axios.post(
//         'https://us-central1-genzvision-8e0d5.cloudfunctions.net/sendEmailWithAttachment',
//         {
//           to: 'venkateshnatarajan786@gmail.com',
//           subject: 'Test Subject',
//           message: 'This is a test email.',
//         },headers,
        
//       );
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//    <CustomNavbar/>
//       <p>sdnsd</p>
//    </div>
//   );
// };

// export default App;


// App.js

// import React from 'react';
// import './App.css'; // Import your CSS file

// const App = () => {
//   return (
//     <div className="app-container">
//       <header>
//         <h1>My Responsive React Page</h1>
//       </header>
//       <main>
//         <section className="content">
//           <p>This is the main content of the page.</p>
//         </section>
//         <aside className="sidebar">
//           <p>This is the sidebar content.</p>
//         </aside>
//       </main>
//       <footer>
//         <p>&copy; 2024 My React App</p>
//       </footer>
//     </div>
//   );
// };

// export default App;



// App.js

import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import './App.css';   // Import your CSS file
import fbLogo from './fbLogo.png'
import instaLogo from './instaLogo.png'
import youtubeLogo from './youtubeLogo.png'
import emailLogo from './emailLogo.png'
import PhoneLogo from './PhoneLogo.png'


import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {db} from './firebase';

import { get, ref, set,update } from "firebase/database";
import TableOfContentApp from './app/TableOfContent';
import Statistics from './app/Statistics';
import BeAwareOf from './app/BeAwareOf';
import EffectsOfWorkPolitics from './app/EffectsWorkPolitics';
import WhatDoReadersSay from './app/WhatDoReadersSay';
import Globe from './app/Globe';
import AboutUS from './app/AboutUS';
import Warning from './app/Warning';

const App = () => {
  const { merchantTransactionId,email } = useParams();
  const navigate = useNavigate();

  const [SmallScreen, setSmallScreen] = useState(null)
  
  const sectionRefs = {
    section1: useRef(null),
    aboutUs: useRef(null),
    section3: useRef(null),
    whatWeOffer: useRef(null),
    whyChooseUs: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    contactUs: useRef(null),
    warning: useRef(null),
    tableOfContent:useRef(null),
    statistics:useRef(null),
    testimonials:useRef(null),
  };


  useEffect((email,merchantTransactionId,navigate) => {


    // Redirect to the specified URL
   
    // Access the width of the screen
    const screenWidth = window.screen.width;

    if (merchantTransactionId !== undefined && merchantTransactionId !==null && merchantTransactionId.length>0) {
        
      console.log("transaction is "+merchantTransactionId)
      const dataParam ={
        merchantTransactionId: merchantTransactionId,
        email:email
      }

const dataRef = ref(db,'users/'+merchantTransactionId);
get(dataRef).then((snapshot) => {
const data = snapshot.val()
    if (snapshot.exists() && !data.success) {
      axios.post(`https://us-central1-genzvision-8e0d5.cloudfunctions.net/phonePayStatus/${merchantTransactionId}/${email}`, {
        ...dataParam   
      }).then(res => { 
    navigate('/', { replace: true });

      console.log("resssssssss" +JSON.stringify(res))
        
      if (res.data.success) {

const dataRef = ref(db,'users/'+merchantTransactionId);
update(dataRef,{

  success:true
})
        
      }
        
      
      
      })
      .catch(error => {
          console.error("error",error);
      }); 
    }

})
        
     
    }



    console.log("Gotttt id :"+merchantTransactionId)
    console.log('Screen Width:', screenWidth);

    // You can perform additional logic based on the screen width
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  }, []);
  const handleScroll = () => {
    // Find the current section by checking which one is in the viewport
    let currentSectionId = null;
    console.log("Handling scroll")
    for (const [sectionId, sectionRef] of Object.entries(sectionRefs)) {
      const rect = sectionRef.current.getBoundingClientRect();

      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentSectionId = sectionId;
      }
    }

    // Scroll to the next section
    if (currentSectionId) {
      const nextSectionId = getNextSectionId(currentSectionId);
      const nextSectionRef = sectionRefs[nextSectionId];

      if (nextSectionRef) {
        nextSectionRef.current.scrollIntoView({
          behavior: 'smooth', 
        });
      }
    }
  };
  const getNextSectionId = (currentSectionId) => {
    // Implement logic to determine the next section based on the current section
    // Example: section1 -> aboutUs, aboutUs -> section3, ..., sectionN -> section1
    // You can customize this logic according to your needs.

    const sectionNumbers = Object.keys(sectionRefs).map((key) =>
      parseInt(key.replace('section', ''), 10)
    );

    const currentSectionNumber = parseInt(
      currentSectionId.replace('section', ''),
      10
    );

    const nextSectionNumber =
      currentSectionNumber % sectionNumbers.length + 1;

    return `section${nextSectionNumber}`;
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <div className="app-container">
      <Navbar />
      
      <main style={{backgroundColor:'#000'}}>
        
        <section id="section1" ref={sectionRefs.section1} className="centered-section-main">
        <br/>
        <br/>
        <div className='StartPageWid'>
          <h1 className="mainIntro">WORK</h1>
          <h1 className="mainIntro" style={{color:'#FFA31A'}}>POLITICS</h1>
          <h1 className="mainIntro">&</h1>
          <h1 className="mainIntro" style={{color:'#FFA31A'}}>GROWTH</h1>
          <h6 className="mainIntroquote" >-- for beginners & mid-level professionals...</h6>
          {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
       </div>
        </section >

        <section  ref={sectionRefs.aboutUs} className="startPadding" id="aboutUs" >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
        <AboutUS/>
          
        </section>

        <br/>
        <br/>
      

        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="warning" className='startPadding' ref={sectionRefs.warning} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <Warning/>

        </section>


      
        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="tableOfContent" className='startPadding' ref={sectionRefs.tableOfContent} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <TableOfContentApp/>

        </section>

        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="statistics" className='startPadding' ref={sectionRefs.statistics} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
          <Statistics/>


       

        
        
        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="whatWeOffer" className='startPadding' ref={sectionRefs.whatWeOffer} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
          <BeAwareOf/>


        </section>

        </section>
        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="whatWeOffer" className='startPadding' ref={sectionRefs.whatWeOffer} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <EffectsOfWorkPolitics/>

        </section>
        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="testimonials" className='startPadding' ref={sectionRefs.testimonials} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <WhatDoReadersSay/>

        </section>

        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="whatWeOffer" className='startPadding' ref={sectionRefs.whatWeOffer} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <Globe/>

        </section>
        
      
      
        {!SmallScreen ?
        <>
        <br/></>
        :<></>
        }

        <section id="contactUs" ref={sectionRefs.contactUs} className="centered-section-contact">

        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
          <h2 className="sectionTitle">Contact us</h2>
          <div className="contentDescContact">
            
          <div 
            className="contactDiv"
          >
            <div className="contactSec">
              <p>Quick Links</p>
                <ul>
                  <li><a href='/privacy'><p>Privacy Policy</p> </a></li>
                  <li><a href='/terms'><p>Terms of Use</p> </a></li>
                  <li><a href='/shipping'><p>Shipping & Delivery Policy</p> </a></li>
                  <li><a href='/cancel'><p>Cancellation & Refund Policies</p> </a></li>
                </ul>
                {/* <p>Follow us at</p>
                <div>
    <a href='https://www.facebook.com/profile.php?id=61555974775883&mibextid=ZbWKwL'>
                <img
          className="imageLogoLink"

            alt="Facebook"
            src={fbLogo}


          />
          </a>
    <a href='https://www.instagram.com/genzvisionofficial/'>

          <img 
          
          className="imageLogoLink"
            alt="Instagram"
            src={instaLogo}
          />
          </a>
          <a
          href='https://www.youtube.com/channel/UCgzigpQe-C3hJxolZZrTGSw'
          >
           <img
                     className="imageLogoLink"

            alt="YouTube"
            src={youtubeLogo}
          />
          </a>
                </div> */}

            </div>
            <div className="contactSec">
           <p> Reach us at</p>
           <div style={{display:'flex',alignContent:'center',}}>
           <img
           style={{marginRight:'10px'}}
            alt=""
            height="42vh"
            src={emailLogo}
          />  
          <p>
          genzvisionuser@gmail.com <br></br>
support@stuffenzy.com
          </p>
          </div>
          <div style={{display:'flex',alignContent:'center',}}>
          <img
            height="42vh"
            style={{marginRight:'10px'}}
          
            alt=""
            src={PhoneLogo}
          />  
          <p>+91 93214 22611</p>
      </div>
            <p>
            STUFFENZY LLP, 46 LF BLDG 5B CTS, <br></br>
1A12B WI LINKING RD,<br></br>
 OPP OSHIWARA DEPOT, GOREGAON WEST,<br></br>
  MUMBAI-400104,
 MAHARASHTRA INDIA<br></br>
            </p>
            </div>
          </div>
          
            </div>

            <br></br>
            <br></br>
            <br></br>
   <footer className="footer"> © Stuffenzy LLP, 2024. All rights reserved. </footer>
    
        </section>
        {/* Add more sections or components as needed */}
      </main>
    </div>
  );
};

export default App;
