import graph from '../images/graph.png'

import './Statistics.css'
const Statistics = () => {
    return (
<div  className='aboutUS-section' >
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',alignItems:'center',}}>
  <div>
<img
          className="statisticBg"

            alt="Facebook"
            src={graph}


          />
          </div>
          <div>
          <p className='contentDescAboutUS'>
          <span style={{color:'#FFA31A'}}>Women:</span> Women frequently face <br/>
          challenges like exclusion from <br/>
           key networks, underestimation, <br/>
            and bias, making them more <br/>
             vulnerable to the negative <br/>
              impacts of office politics in <br/>many countries.
          </p>
          </div>
</div>
<br/>
<p className='contentDescAboutUS'>
<span style={{color:'#FFA31A'}}>Men: </span>Men might experience different forms of office politics, such as power struggles or competitive pressures, which can also affect their work satisfaction and mental health.</p>
</div>)
}



export default Statistics;