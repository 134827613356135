import stressAnxiety from '../images/stressAnxiety.png'
import lossMotivation from '../images/lossMotivation.png'
import selfDoubt from '../images/selfDoubt.png'
import frustrationAnger from '../images/frustrationAnger.png'
import isolation from '../images/isolation.png'
import fatigueBurnout from '../images/fatigueBurnout.png'
import headaches from '../images/headaches.png'
import sleep from '../images/sleep.png'
import weakened from '../images/weakened.png'
import digestiveIssues from '../images/digestiveIssues.png'
import './EffectsWorkPolitics.css'

const EffectsOfWorkPolitics = () => {
    return (
<div className='aboutUS-section' > 
<h2 className="sectionTitle">Effects of work politics on Mental & Physical Health</h2>
<br/>

<h6 className="sectionTitleColor">Mental Health</h6>
<br/>

<div style={{display:'flex', flexDirection:'row',  justifyContent:'space-between'}}>

    <div>

<img
          className="aboutUsStyle"

            alt="Facebook"
            src={stressAnxiety}
            style={{height:'10vw',width:'13vw'}}

          />
                  <br/>
                  <p className='tableTextPolitics'>Stress and Anxiety</p>
    </div>
         
    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={lossMotivation}
          />
          <br/>
          <p className='tableTextPolitics'>Loss of Motivation</p>
    </div>
    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={selfDoubt}
          />
          <br/>
          <p className='tableTextPolitics'>Self-Doubt and <br/>Low Self-Esteem</p>
    </div>

    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={frustrationAnger}
          />
          <br/>
          <p className='tableTextPolitics'>Frustration and Anger</p>
    </div>
    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={isolation}
          />
          <br/>
   <p className='tableTextPolitics'>Isolation</p>
    </div>
   
</div>
<br/>
<br/>
<h6 className="sectionTitleColor">Physical Health</h6>
<br/>

<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>


<div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={fatigueBurnout}
           
          />
          <br/>
          <p className='tableTextPolitics'>Fatigue and Burnout</p>
   
    </div>


    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={headaches}
          />
          <br/>
          <p className='tableTextPolitics'>Headaches and Muscle <br/>Tension</p>

    </div>


    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={sleep}
          />
          <br/>
          <p className='tableTextPolitics'>Sleep Disturbances</p>
    </div>



    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={digestiveIssues}
          />
          <br/>
          <p className='tableTextPolitics'>Digestive Issues</p>
    </div>

    <div>

<img
          className="aboutUsStyle"
          style={{height:'10vw',width:'13vw'}}

            alt="Facebook"
            src={weakened}
          />
          <br/>
          <p className='tableTextPolitics'>Weakened Immune System</p>
    </div>

   
</div>











</div>


)
}



export default EffectsOfWorkPolitics;