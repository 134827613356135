
import warningImage from '../images/warningImage.png'
import './Warning.css'
const Warning = () => {
    return (
        <div  className='aboutUS-section'>

          <h2 className="sectionTitle">Warnings</h2> <br/>
          <p className="contentDescAboutUS">Competition for growth is real. Most of the time you compete with your colleagues &<br/> few times you compete to be part of the management team. Remember, no one is<br/> your FRIEND at the workplace. Don’t be fooled by the word <span style={{color:'#FF9F1A'}}>"FRIENDS" </span> at <br/>the workplace.
<br></br>
<br></br>

            </p>

            <div  style={{display:'flex',justifyContent:'space-around',flexDirection:'row',alignItems:'center'}}>
          <img
          className="warningBg"

            alt="Facebook"
            src={warningImage}


          />
           <div>
           <p style={{fontSize:'2vw',color:'#fff'}}>
           Never share this copy of content with your <br/> colleagues. They might use the trips & tricks <br/> against you. End of the day its a individual journey.
           
           </p>

<br/>
            </div>
            </div>
            <br/>
            <br/>

        <div  style={{display:'flex',justifyContent:'space-around',flexDirection:'row',alignItems:'center'}}>
  <a href='/form'> <button  className="buttonStyle">Get my copy</button></a> 
  </div>
        </div>
    )}
export default Warning;
