import toxicColleagues from '../images/toxicColleagues.png'
import micromanagers from '../images/micromanagers.png'
import creditTakers from '../images/creditTakers.png'
import passiveAggressors from '../images/passiveAggressors.png'
import narcissists from '../images/narcissists.png'
import './BeAwareOf.css'

const BeAwareOf = () => {
    return (
<div   className='aboutUS-section'> 
<h2 className="sectionTitle">Be Aware Of</h2>

<div 
className='imageLayout'
   
>
  <div style={{display:'flex', flexDirection:'row',  alignItems:'center', minWidth:'40vw',}}>
  
  <div>
    
    <img
          className="beAwareOfImage"

            alt="Facebook"
            src={toxicColleagues}


          />
          </div>
          <div className='tableText'>
          <p style={{color:'#FFA31A'}}>
          Toxic Colleagues
          </p>
          <p >
          Those who spread <br/>negativity, gossip, or <br/> undermine others.</p>
          </div>
          
  </div>


  <div style={{display:'flex', flexDirection:'row',  alignItems:'center', minWidth:'40vw'}}>
  <div>

<img
          className="beAwareOfImage"

            alt="Facebook"
            src={micromanagers}


          />
</div>
          <div  className='tableText'>
          <p style={{color:'#FFA31A'}}>

          Micromanagers
          </p>
          <p >
          Supervisors who overly <br/> control or scrutinize every <br/>detail of your work.
          </p>
          </div>
          
</div>
</div>



<div 
className='imageLayout'

>

<div style={{display:'flex', flexDirection:'row',  alignItems:'center', minWidth:'40vw'}}>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={creditTakers}


          />
          <div  className='tableText'>
          <p style={{color:'#FFA31A'}}>

          Credit Takers
          </p>
          <p >
          Individuals who take <br/>credit for others' ideas<br/> or efforts.
          </p>
          </div>
          
</div>
<div style={{display:'flex', flexDirection:'row',  alignItems:'center', minWidth:'40vw'}}>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={passiveAggressors}


          />
          <div className='tableText' >
          <p style={{color:'#FFA31A'}}>

          Passive-Aggressors
          </p>
          <p >
          Those who use indirect <br/> resistance or subtle negativity.
          </p>
          </div>
          
</div>
</div>









<div 
className='imageLayout'

>

<div style={{display:'flex', flexDirection:'row',  alignItems:'center', minWidth:'40vw'}}>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={narcissists}


          />
          <div className='tableText' >
          <p style={{color:'#FFA31A'}}>

          Narcissists
          </p>
          <p >
          Colleagues who prioritize their <br/> interests and often manipulate <br/>situations for personal gain.
          </p>
          </div>

          
</div>
<div style={{display:'flex', flexDirection:'row', justifyContent:'left', alignItems:'center', minWidth:'40vw'}}>

<a href='/form'> <button  className="buttonStyle">Get your copy</button></a>  
</div>


</div>

</div>)
}



export default BeAwareOf;