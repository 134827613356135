
import Sophie from '../images/Sophie.png'
import Jack from '../images/Jack.png'
import Aarav from '../images/Aarav.png'
import Neha from '../images/Neha.png'
import Vikram from '../images/Vikram.png'
import Emily from '../images/Emily.png'
import './WhatDoReadersSay.css'

const WhatDoReadersSay = () => {
    return (
<div  className='aboutUS-section'>
<h2 className="sectionTitle">What Do Readers Say..</h2>
<div 
className='imageLayout'
   
>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Sophie} 
               />

</div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Sophie Williams, Software Developer <br/>
          (Mid-Level - USA)
          </p>
          <p >
          "This e-book is a must-read! I was struggling with office <br/>
          politics, and ‘Work, Politics & Growth’ gave me the tools<br/>
           to rise above it. It’s helped me focus on my growth and<br/>
            build stronger relationships with my colleagues."
          </p>
          </div>
          
</div>


<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Jack}


          />
          </div>
          <div
           className='tableTextReader'
          >
          <p style={{color:'#FFA31A'}}>
          Jack Thompson, Project Manager <br/>
          (Mid-Level - Australia )
          </p>
          <p >
          "‘Work, Politics & Growth’ offered practical advice that <br/>
          resonated deeply with my experiences. It’s helped me<br/>
           navigate complex workplace dynamics and align my<br/>
            team towards shared goals. Highly recommend for<br/>
             any professional looking to advance."
          </p>
          </div>
          
</div>

</div>




<div 
className='imageLayout'
   
>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>

<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Aarav}


          />
          </div>
          <div
          className='tableTextReader'
          
          
          >
          <p style={{color:'#FFA31A'}}>
          Aarav Sharma, Marketing Associate <br/> 
          (Fresher - India)
          </p>
          <p >
          "‘Work, Politics & Growth’ has been a game-changer for<br/>
           me. As a newcomer, navigating office dynamics was<br/>
            daunting. This e-book provided clear strategies that <br/>
            helped me establish myself confidently and avoid<br/>
             common pitfalls."
          </p>
          </div>
          
</div>

<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Emily}


          /></div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Emily Johnson, Financial Analyst <br/>
           (Fresher - UK)
          </p>
          <p>
          "As a recent graduate, I was anxious about dealing with <br/>
          office politics. This e-book gave me the confidence and <br/>
          strategies to not just survive but thrive. It's a valuable <br/>
          guide for anyone entering the workforce."
          </p>
          </div>
          
</div>
</div>





<div 
className='imageLayout'
   
>
<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Neha}


          /></div>
          <div
            className='tableTextReader'
          
          >
          <p style={{color:'#FFA31A'}}>
          Neha Gupta, HR Specialist <br/>
          (Fresher - India)
          </p>
          <p >
          "Starting in HR, I knew office politics would be part of<br/>
           the job, but this e-book opened my eyes to the nuances<br/>
            of handling it professionally. It’s been instrumental in <br/>
            shaping my approach and making a positive impact in<br/>
             my role."
          </p>
          </div>
          
</div>


<div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',width:'40vw' }}>
<div
            

>
<img
          className="ReadersImage"

            alt="Facebook"
            src={Vikram}


          /></div>
          <div
          className='tableTextReader'
          >
          <p style={{color:'#FFA31A'}}>
          Vikram Singh, Operations Supervisor <br/>(Mid-Level - India)
          </p>
          <p >
          "This e-book changed how I view and handle workplace <br/>
          politics. It’s not just about playing the game; it’s about <br/>
          understanding the landscape and using it to your<br/>
           advantage. ‘Work, Politics & Growth’ has been a key <br/>
           resource in my career progression."
          </p>
          </div>
          
</div>
</div>

</div>)
}



export default WhatDoReadersSay;