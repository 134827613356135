
import aboutUsImage from '../images/aboutUsImage.png'
import './AboutUS.css'
const AboutUS = () => {
    return (
<div  className='aboutUS-section'>


            
<h2 className="sectionTitle" >About Us</h2><br/>
          <p className="contentDescAboutUS">
         <span style={{color:'#FF9F1A'}}> GenZvision </span> is a thriving community of experienced professionals from various<br/> industries across the globe, all united by <span style={{color:'#FF9F1A'}}>one vision: 'No more victims of office politics</span> 
<br></br>
<br></br>
To stop the young professionals from becoming prey to office politics the community<br/> has put up various tips & tricks that can used at the workplace to tackle office politics &<br/> build a successful career.
          </p>
          <br></br>

          <tr>
          <div  style={{display:'flex',justifyContent:'space-around',flexDirection:'row',textAlign:'center',alignItems:'center'}}>
          <img
          className="aboutUsBg"

            alt="Facebook"
            src={aboutUsImage}


          />
           <div>
           <p style={{fontSize:'2.5vw',color:'#fff'}}>Explore the <br/><span style={{color:'#FFA31A'}}>'Table of Contents'</span><br/> to discover the <br/>actionable tips that <br/>awaits you.</p>
           </div>
          </div>
         
          </tr>
        
</div>)}

export default AboutUS;